import { DISTINCT_SUPC } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

// Gets all business centers
function* fetchDistinctSupcListAsync({ bc, ig, ag, brandType }) {
  try {
    console.log('inside api call attribute groups');
    const supcList = yield getRequest(
      `/brandconversion/supc?bc=${encodeURIComponent(bc)}&ig=${encodeURIComponent(ig)}&ag=${encodeURIComponent(ag)}&brandType=${encodeURIComponent(brandType)}`
    );
    console.log('data', supcList);
    yield put({ type: DISTINCT_SUPC.SUCCESS, payload: { supcList } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: DISTINCT_SUPC.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH SUPC DETAILS"
      })
    );
  }
}

export { fetchDistinctSupcListAsync };
