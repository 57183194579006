import React, { useEffect, useState } from 'react';
import { Button, Select, Space, Table, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import * as actionCreators from '../actions';
import AppLoader from './AppLoader';
import { CaretUpOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import DoNotConvertBrandModal from './DoNotConvertBrandModal';

export default function SearchFilters() {
  const dispatch = useDispatch();

  // selectors
  const fetchingList = useSelector(state => _.get(state.itemList, 'fetchingList', false));
  const businessCenterList = useSelector(state => _.get(state.businessCenters, 'businessCenters', []));
  const itemGroupList = useSelector(state => _.get(state.itemGroups, 'itemGroups', []));
  const attributeGroupList = useSelector(state => _.get(state.attributeGroups, 'attributeGroups', []));
  const isLoadingIg = useSelector(state => _.get(state.isLoadingItemGroup, 'isLoadingItemGroup', false));
  const isLoadingAg = useSelector(state => _.get(state.isLoadingAttributeGroup, 'isLoadingAttributeGroup', false));
  const isLoadingBc = useSelector(state => _.get(state.isLoadingBusinessCenters, 'isLoadingBusinessCenters', false));
  const filteredItemList = useSelector(state => _.get(state.allItemList, 'allItemList', []));
  const pairTypes = useSelector(state => _.get(state.pairTypes, 'pairTypes', []));
  const filterParams = useSelector(state => _.get(state.filterParams, 'filterParams', {}));
  const itemDetails = useSelector(state => _.get(state.itemList, 'allItemList', []));
  // states
  const [collapsedHeader, setcollapsedHeader] = useState(false);
  const [showOsdAttributes, setShowOsdAttributes] = useState(false);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [selectedBusinessCenter, setSelectedBusinessCenter] = useState(null);
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState(null);
  const [selectedPairTypes, setSelectedPairTypes] = useState(['All']);
  const [showOtherAttributes, setShowOtherAttributes] = useState(false);
  const [isDoNotConvertModalVisible, setIsDoNotConvertModalVisible] = useState(false);
  const [showOsdAttributeString, setShowOsdAttributeString] = useState(false);
  const columnSorters = useSelector(state => _.get(state, 'columnSorters', []));
  const columnFilters = useSelector(state => _.get(state, 'columnFilters', []));
  /**
   * fetching all business centers.
   */
  useEffect(() => {
    if (businessCenterList.length === 0) {
      dispatch(actionCreators.fetchBusinesscenters());
    }
  }, [businessCenterList]);

  /**
   * fetching all item groups.
   */
  useEffect(() => {
    if (selectedBusinessCenter) {
      dispatch(actionCreators.fetchItemGroups({ bc: selectedBusinessCenter }));
    }
  }, [selectedBusinessCenter]);

  /**
   * fetching all attribute groups.
   */
  useEffect(() => {
    if (selectedBusinessCenter && selectedItemGroup) {
      dispatch(actionCreators.fetchAttributeGroups({ bc: selectedBusinessCenter, ig: selectedItemGroup }));
    }
  }, [selectedBusinessCenter, selectedItemGroup]);

  /**
   * fetching all pair types.
   */
  useEffect(() => {
    if (pairTypes.length === 0) {
      dispatch(actionCreators.fetchAllPairTypes());
    }
  }, [pairTypes]);

  useEffect(() => {
    updateMainFilterValuesToStore();
  }, [
    selectedBusinessCenter,
    selectedItemGroup,
    selectedAttributeGroup,
    selectedPairTypes,
    showOsdAttributes,
    showOtherAttributes,
    showOsdAttributeString
  ]);

  const toggleCollapsed = () => {
    setcollapsedHeader(!collapsedHeader);
    // setTimeout(ResizeTable, 300);
  };

  const handleSearchButtonClick = () => {
    console.log(filterParams);
    // Initialize an object to store the sorting parameters
    const sortParams = { asc: [], desc: [] };

    // Iterate over the columnSorters array
    if (Array.isArray(columnSorters)) {
      // Iterate over the columnSorters array
      columnSorters.forEach(sorter => {
        const key = sorter.key;
        dispatch(actionCreators.resetColumnSorts({ payload: { key } }));
      });
    }
    if (Array.isArray(columnFilters)) {
      // Iterate over the columnSorters array
      columnFilters.forEach(filter => {
        const key = filter.key;
        dispatch(actionCreators.resetColumnFilters({ payload: { key } }));
      });
    }

    dispatch(
      actionCreators.fetchItemList({
        bc: filterParams?.bc,
        ig: filterParams?.ig,
        ag: filterParams?.ag,
        searchParams: {},
        pageNo: 0,
        pageSize: filterParams?.pageSize ? filterParams?.pageSize : 10,
        sortParams: sortParams
      })
    );
    // load valid attributes for the selected type.
    if (
      selectedPairTypes.length === 1 &&
      !selectedPairTypes.includes('ALL') &&
      !selectedPairTypes.includes('I') &&
      !selectedPairTypes.includes('C')
    ) {
      dispatch(
        actionCreators.getValidAttributes({
          bc: filterParams?.bc,
          ig: filterParams?.ig,
          ag: filterParams?.ag,
          pairType: selectedPairTypes[0]
        })
      );
    }
  };

  const handleBusinessCenterChange = businessCenter => {
    setSelectedBusinessCenter(businessCenter);
    setSelectedItemGroup(null);
    setSelectedAttributeGroup(null);
  };

  const handleItemGroupChange = itemGroup => {
    setSelectedItemGroup(itemGroup);
    setSelectedAttributeGroup(null);
  };

  const handleAttributeGroupChange = attributeGroup => {
    setSelectedAttributeGroup(attributeGroup);
  };

  const handlePairTypeChange = selectedValues => {
    if (selectedValues[selectedValues.length - 1] === 'All') {
      // If 'All' is added as the last element, remove others and set 'All' as selected
      selectedValues = selectedValues.filter(value => value == 'All');
      setShowOsdAttributes(false);
    } else if (selectedValues.includes('All') && selectedValues.length > 1) {
      // Remove 'All' from the selected values
      selectedValues = selectedValues.filter(value => value !== 'All');
      setShowOsdAttributes(false);
      setShowOsdAttributeString(false);
    } else if (!selectedValues.includes('All')) {
      // If 'All' is not selected, remove it from the selected  values
      let selected = selectedValues.filter(value => value !== 'All');
      setSelectedPairTypes(selected.length >= pairTypes.length || selected.length === 0 ? 'All' : selected);
      setShowOsdAttributeString(false);
      setShowOsdAttributes(false);
      return;
    }
    console.log(`Selected: ${selectedValues}`);
    setSelectedPairTypes(selectedValues);
    console.log(`Selected pair type array : ${selectedPairTypes}`);
  };

  // update all main filter changes to store.
  const updateMainFilterValuesToStore = () => {
    let searchParams = {};
    if (selectedPairTypes.length > 0 && !(selectedPairTypes.includes('All') && selectedPairTypes.length === 1)) {
      searchParams['type'] = selectedPairTypes;
    }

    const newFilterParams = {
      ...filterParams, // Preserve other fields in filterParams
      searchParams: searchParams,
      bc: selectedBusinessCenter,
      ig: selectedItemGroup,
      ag: selectedAttributeGroup,
      showOsdAttributes: showOsdAttributes,
      showOtherAttributes: showOtherAttributes,
      showOsdAttributeString: showOsdAttributeString
    };
    dispatch(actionCreators.saveFilterParams({ payload: newFilterParams }));
  };

  // Function to handle modal visibility
  const showDoNotConvertModal = () => {
    setIsDoNotConvertModalVisible(true);
  };

  if (fetchingList) {
    return <AppLoader show />;
  } else {
    return (
      <>
        {/* Modal component - Do Not Convert Brand List */}
        <DoNotConvertBrandModal
          isVisible={isDoNotConvertModalVisible}
          handleCancel={() => setIsDoNotConvertModalVisible(false)}
        />

        <div className={`grid-filter-panel ${collapsedHeader && 'collapsed'}`} id="grid-filter-panel">
          <div
            className="back-to-dashboard"
            onClick={() => {
              window.location.href = '/suite/dashboard';
            }}
          />
          <div className="bread-crum-seperator"></div>
          <div className="title">Item Relationship Pairs</div>

          {/* Right top corner link */}
          <div className="sample-link" style={{ position: 'absolute', top: '10px', right: '10px', display: 'none' }}>
            <Button type="link" onClick={showDoNotConvertModal}>
              <u>View/Maintain Do Not Convert Brands</u>
            </Button>
          </div>
        </div>
        <div style={{ margin: '11px 25px -7px 25px', fontSize: '16px', fontFamily: 'BrixSansRegular' }}>
          Welcome Back , to begin select a Business center, item group, and attribute group.
        </div>
        <div className={`filter-wrapper ${collapsedHeader && 'collapsed'}`} id="filter-wrapper">
          <div className="filterbar">
            <div className="filtergroup market">
              <div className="filtergroup-title">BUSINESS CENTER</div>
              <div className="filtergroup-row">
                <div className="filteritem">
                  <Select
                    showArrow
                    showSearch
                    className="filter-select"
                    placeholder="Business center"
                    disabled={businessCenterList.length <= 0}
                    onChange={handleBusinessCenterChange}
                    value={selectedBusinessCenter}
                    loading={isLoadingBc}
                  >
                    {businessCenterList.length > 0 &&
                      businessCenterList
                        .sort((a, b) => a.bcName.localeCompare(b.bcName))
                        .map(obj => {
                          return (
                            <Select.Option key={obj.bcName} value={obj.bcName}>
                              {obj.bcName}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="filtergroup item-grp">
              <div className="filtergroup-title">ITEM GROUP</div>
              <div className="filtergroup-row">
                <div className="filteritem">
                  <Select
                    showArrow
                    showSearch
                    className="filter-select"
                    placeholder="Item Group"
                    disabled={itemGroupList.length <= 0 || selectedBusinessCenter == null}
                    onChange={handleItemGroupChange}
                    value={selectedItemGroup}
                    loading={isLoadingIg}
                  >
                    {itemGroupList.length > 0 &&
                      itemGroupList
                        .sort((a, b) => a.igName.localeCompare(b.igName))
                        .map(obj => {
                          return (
                            <Select.Option key={obj.igName} value={obj.igName}>
                              {obj.igName}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="filtergroup attribute-grp">
              <div className="filtergroup-title">ATTRIBUTE GROUP</div>
              <div className="filtergroup-row">
                <div className="filteritem">
                  <Select
                    showArrow
                    showSearch
                    className="filter-select"
                    placeholder="Item Group"
                    disabled={
                      attributeGroupList.length <= 0 || selectedBusinessCenter == null || selectedItemGroup == null
                    }
                    value={selectedAttributeGroup}
                    loading={isLoadingAg}
                    onChange={handleAttributeGroupChange}
                  >
                    {attributeGroupList.length > 0 &&
                      attributeGroupList
                        .sort((a, b) => a.agName.localeCompare(b.agName))
                        .map(obj => {
                          return (
                            <Select.Option key={obj.agName} value={obj.agName}>
                              {obj.agName}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="filtergroup item-grp" style={{ marginLeft: '30px' }}>
              <div className="filtergroup-title">PAIR TYPES</div>
              <div className="filtergroup-row">
                <div className="filteritem">
                  {/* multi select */}
                  <Space
                    direction="horizontal"
                    style={{
                      width: '20%'
                    }}
                  >
                    <Select
                      className="filter-select"
                      mode="multiple"
                      size="medium"
                      placeholder="Please select"
                      onChange={handlePairTypeChange}
                      value={selectedPairTypes}
                      style={{
                        width: '190px'
                      }}
                      options={[
                        // Add the "All" option
                        { label: 'All', value: 'All' },
                        // Map the pairTypes to label and value
                        ...pairTypes.map(pair => ({ label: pair.type, value: pair.type }))
                      ]}
                    />
                  </Space>
                </div>
              </div>
            </div>
            <div className="filtergroup">
              <div className="filtergroup-title filter-title-search"></div>
              <div className="filtergroup-row">
                <div className="filteritem">
                  <Button
                    type="primary"
                    className="btn searchbtn"
                    style={{ width: '140px' }}
                    onClick={handleSearchButtonClick}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>

            <div className="additional-attributes-wrapper" style={{ marginLeft: '25px', marginTop: '17px' }}>
              <div style={{ marginBottom: '10px' }}>
                <div className="additional-attributes-switch">
                  Show Other Columns
                  <Switch
                    onChange={e => setShowOtherAttributes(e)}
                    size="small"
                    style={{ marginLeft: '10px' }}
                    disabled={itemDetails.length == 0}
                    checked={filterParams?.showOtherAttributes}
                  />
                </div>
              </div>

              {!(
                selectedPairTypes.length === 0 ||
                selectedPairTypes.length > 1 ||
                selectedPairTypes.includes('All') ||
                selectedPairTypes.includes('I') ||
                selectedPairTypes.includes('C')
              ) &&
                selectedBusinessCenter != null && selectedItemGroup != null && selectedAttributeGroup != null && (
                  <div>
                    <div
                      className="additional-attributes-switch"
                      hidden={
                        selectedPairTypes.length === 0 ||
                        selectedPairTypes.length > 1 ||
                        selectedPairTypes.includes('All') ||
                        selectedBusinessCenter == null ||
                        selectedItemGroup == null ||
                        selectedAttributeGroup == null
                      }
                    >
                      Show OSD Attributes
                      <Switch
                        disabled={itemDetails.length == 0}
                        onChange={e => setShowOsdAttributes(e)}
                        size="small"
                        style={{ marginLeft: '10px' }}
                        checked={filterParams?.showOsdAttributes}
                      />
                    </div>
                  </div>
                )}
              {(selectedPairTypes.length === 0 ||
                selectedPairTypes.length > 1 ||
                selectedPairTypes.includes('All') ||
                selectedBusinessCenter == null || selectedItemGroup == null || selectedAttributeGroup == null) &&
                !selectedPairTypes.includes('I') &&
                !selectedPairTypes.includes('C') && (
                  <div
                    className="additional-attributes-switch"
                    hidden={
                      !(selectedPairTypes.length > 1 || selectedPairTypes.includes('All')) &&
                      !(selectedBusinessCenter == null || selectedItemGroup == null || selectedAttributeGroup == null)
                    }
                  >
                    Show OSD Attributes String
                    <Switch
                      onChange={e => setShowOsdAttributeString(e)}
                      size="small"
                      style={{ marginLeft: '10px' }}
                      disabled={itemDetails.length == 0}
                      checked={filterParams?.showOsdAttributeString}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}