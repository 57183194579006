import {
  Space,
  Table,
  Button,
  Input,
  Pagination,
  Select,
  Modal,
  Radio,
  Spin,
  Form,
  Tooltip,
  notification,
  Tag
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import * as actionCreators from '../actions';
import DoNotConvertBrandModal from './DoNotConvertBrandModal';
import moment from 'moment';
import generateCalendar from 'antd/lib/calendar/generateCalendar';
import { put } from 'redux-saga/effects';
const reportURL = process.env.REACT_APP_REPORT_API;

export default function ItemDetailsTable() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // selectors
  const itemDetails = useSelector(state => _.get(state.itemList, 'allItemList', []));
  const filterParams = useSelector(state => _.get(state.filterParams, 'filterParams', {}));
  const pairTypes = useSelector(state => _.get(state.pairTypes, 'pairTypes', []));
  const supcList = useSelector(state => _.get(state.supcList, 'supcList', []));
  const isLoadingSupc = useSelector(state => _.get(state.supcList, 'isLoadingSupc', false));
  const itemChanges = useSelector(state => _.get(state.itemChanges, 'itemChanges', []));
  const isSavingPair = useSelector(state => _.get(state.newPair, 'isSavingPair', false));
  const columnFilters = useSelector(state => _.get(state, 'columnFilters', []));
  const columnSorters = useSelector(state => _.get(state, 'columnSorters', []));
  const validAttributes = useSelector(state => _.get(state.validAttributes, 'validAttributes', []));
  const isDeletedPair = useSelector(state => _.get(state.validateNewPair, 'isValidPair', false));
  const isChecking = useSelector(state => _.get(state.validateNewPair, 'isChecking', false));
  const isValidPair = useSelector(state => _.get(state.validateNewPair, 'isValidPair', null));
  const isSavingItemChanges = useSelector(state => _.get(state.itemChanges, 'isSavingItemChanges', false));
  const isFaild = useSelector(state => _.get(state.newPair, 'isFaild', false));
  const user = useSelector(state => _.get(state, 'user', []));
  const fetchingList = useSelector(state => _.get(state.itemList, 'fetchingList', false));

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);
  const [comparisonOperator, setComparisonOperator] = useState('EQUAL');
  const [otherAttributeColumns, setOtherAttributeColumns] = useState([
    'fromUsageLotName',
    'toUsageLotName',
    'fromUsageLotUuomName',
    'toUsageLotUuomName',
    'fromEquivalentUomQty',
    'toEquivalentUomQty',
    'fromUomQty',
    'toUomQty',
    'fromUomName',
    'toUomName',
    'fromUnivUomQty',
    'toUnivUomQty',
    'fromUnivUomName',
    'toUnivUomName'
  ]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState(itemDetails?.allItemList?.data || []);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [visible, setVisible] = useState(false);

  const [isFromSupcModalVisible, setFromSupcModalVisible] = useState(false);
  const [isToSupcModalVisible, setToSupcModalVisible] = useState(false);
  const [selectedToSupc, setSelectedToSupc] = useState('');
  const [selectedFromSupc, setSelectedFromSupc] = useState('');
  const [selectedPairType, setSelectedPairType] = useState('');
  const [newPairFromSupc, setNewPairFromSupc] = useState('');
  const [newPairToSupc, setNewPairToSupc] = useState('');
  const [fromSupcSelectedType, setFromSupcSelectedType] = useState('contains');
  const [toSupcSelectedType, setToSupcSelectedType] = useState('contains');
  const [filteredSupcList, setFilteredSupcList] = useState([]);
  const [visibleItems, setVisibleItems] = useState(10);
  const [updatedPairTypes, setUpdatedPairTypes] = useState([]);
  const [deletedPairs, setDeletedPairs] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDoNotConvertModalVisible, setIsDoNotConvertModalVisible] = useState(false);
  const [fromInputValue, setFromInputValue] = useState('');
  const [toInputValue, setToInputValue] = useState('');
  const [resetKey, setResetKey] = useState(Date.now());
  const [isSubmittedNewPair, setIsSubmittedNewPair] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [newPairPageNo, setNewPairPageNo] = useState(0);
  const [newPairTotal, setNewPairTotal] = useState(0);
  const previousPageRef = useRef(currentPage);
  const [triggerBySearch, setTriggerBySearch] = useState(true);

  /**
   * handle table columns.
   */
  useEffect(() => {
    console.log('catch the change');
    arrangeColumns();
    if (filterParams.showOsdAttributes) {
      arrangeOsdAttributeData();
    }
    setTableData(itemDetails?.allItemList?.data);
  }, [filterParams.showOtherAttributes, filterParams.showOsdAttributes, filterParams.showOsdAttributeString]);

  /**
   * handle table data.
   */
  useEffect(() => {
    arrangeColumns();
    if (filterParams.showOsdAttributes) {
      arrangeOsdAttributeData();
    }
    // ste table rerender
    const filter = columnFilters?.columnFilters?.length ?? columnFilters?.length;
    const sort = columnSorters?.columnSorters?.length ?? columnSorters?.length;
    if (filter !== undefined && filter === 0 && sort !== undefined && sort === 0) {
      setResetKey(Date.now());
      setTriggerBySearch(true);
    }
    setTableData(itemDetails?.allItemList?.data);
    setTotalRecordCount(itemDetails?.allItemList?.total);
    //clear edit and delete mark rows
    setUpdatedPairTypes([]);
    setDeletedPairs([]);
    // Reset the selectedRowKeys state when the table is re-rendered
    setSelectedRowKeys([]);
  }, [itemDetails?.allItemList?.data]);

  /**
   * update page number and size.
   */
  useEffect(() => {
    console.log('use effect tablejs', { filterParams });
    const pageNo = currentPage ? currentPage : 0;
    const tempPageSize = pageSize ? pageSize : 10;
    const clone = { ...filterParams, pageNo, pageSize: tempPageSize };
    console.log(columnFilters);
    // Merge columnFilters with existing searchParams
    const mergedSearchParams = { ...clone.searchParams };
    Object.values(columnFilters).forEach(filter => {
      mergedSearchParams[filter.key] = filter.value;
    });
    const payload = {
      ...clone,
      searchParams: mergedSearchParams
    };
    dispatch(actionCreators.saveFilterParams({ payload: clone }));
    //clear edit and delete mark rows
    setUpdatedPairTypes([]);
    setDeletedPairs([]);
    // Reset the selectedRowKeys state when the table is re-rendered
    setSelectedRowKeys([]);
    // Initialize an object to store the sorting parameters
    const sortParams = { asc: [], desc: [] };

    // Iterate over the columnSorters array
    if (Array.isArray(columnSorters)) {
      // Iterate over the columnSorters array
      columnSorters.forEach(sorter => {
        if (sorter.value === 'ascend') {
          sortParams.asc.push(sorter.key);
        } else if (sorter.value === 'descend') {
          sortParams.desc.push(sorter.key);
        }
      });
    }
    //load tale data.
    if (itemDetails?.allItemList?.data?.length > 0 && previousPageRef.current == currentPage && !triggerBySearch && !fetchingList) {
      dispatch(
        actionCreators.fetchItemList({
          bc: filterParams?.bc,
          ig: filterParams?.ig,
          ag: filterParams?.ag,
          searchParams: mergedSearchParams,
          pageNo: currentPage,
          pageSize: pageSize,
          sortParams: sortParams
        })
      );
    }
  }, [currentPage, pageSize, itemChanges, columnFilters, columnSorters]);

  useEffect(() => {
    if (supcList && supcList?.supcList?.data?.data?.length > 0) {
      const sortedFilteredSupc = supcList?.supcList?.data?.data.slice().sort((a, b) => {
        return a.itemDescription.localeCompare(b.itemDescription);
      });
      setFilteredSupcList(sortedFilteredSupc);
      setNewPairTotal(supcList?.supcList?.data?.total);
    } else {
      setFilteredSupcList([]);
      setNewPairTotal(0);
    }
  }, [supcList]);

  /**
   * Handle add new pair value saving.
   */
  useEffect(() => {
    setVisible(isSavingPair);
    if (!isSavingPair) {
      setSelectedPairType('');
      setNewPairToSupc('');
      setNewPairFromSupc('');
    }
  }, [isSavingPair]);

  useEffect(() => {
    if (isSubmittedNewPair && !isChecking) {
      if (isDeletedPair?.deletedPairs) {
        showConfirmation();
      } else if (
        isDeletedPair?.fromHierId != null &&
        isDeletedPair?.toHierId != null &&
        isDeletedPair?.fromHierId != isDeletedPair?.toHierId
      ) {
        showDifferentHierarchyConfirmation();
      } else {
        const payload = {
          pairType: selectedPairType,
          fromSupc: newPairFromSupc,
          toSupc: newPairToSupc,
          bc: isDeletedPair?.bcFromSupc,
          ig: isDeletedPair?.igFromSupc,
          ag: isDeletedPair?.agFromSupc
        };
        dispatch(actionCreators.saveNewPairMapping({ payload }));
      }
      setIsSubmittedNewPair(false);
    }
  }, [isDeletedPair, isChecking]);

  useEffect(() => {
    setPermissions(user?.data?.permissions);
  }, [user]);

  useEffect(() => {
    const newPage = itemDetails?.allItemList?.currentPage;
    // Compare the previous and current page values
    if (previousPageRef.current !== newPage && typeof newPage !== 'undefined' && !fetchingList) {
      setCurrentPage(newPage);
      setTriggerBySearch(true);
      // Update previous page reference after comparison
      previousPageRef.current = newPage;
    }
  }, [itemDetails?.allItemList?.data]);

  const showConfirmation = () => {
    Modal.confirm({
      title: 'This pair has already been deleted. Do you want to proceed with a new lot number?',
      content: (
        <div>
          <p>From Supc: {isValidPair.fromSupc}</p>
          <p>To Supc: {isValidPair.toSupc}</p>
          <p>Lot No: {isDeletedPair?.deletedPairs?.lotNumber}</p>
          {isDeletedPair?.fromHierId != null &&
          isDeletedPair?.toHierId != null &&
          isDeletedPair?.fromHierId != isDeletedPair?.toHierId ? (
            <div>
              <p>The selected SUPC pair belongs to different BC-IG-AG categories. Do you wish to proceed?</p>
              <hr />
              <div>
                <p>From Supc: {isValidPair.fromSupc}</p>
                <p>
                  BC-IG-AG:{' '}
                  {`${isDeletedPair?.bcFromSupc || ''}-${isDeletedPair?.igFromSupc || ''}-${isDeletedPair?.agFromSupc ||
                    ''}`}
                </p>
                <hr />
                <p>To Supc: {isValidPair.toSupc}</p>
                <p>
                  BC-IG-AG:{' '}
                  {`${isDeletedPair?.bcToSupc || ''}-${isDeletedPair?.igToSupc || ''}-${isDeletedPair?.agToSupc || ''}`}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      ),
      onOk() {
        // Handle confirmation
        console.log('Confirmed');
        const payload = {
          pairType: selectedPairType,
          fromSupc: newPairFromSupc,
          toSupc: newPairToSupc,
          bc: isDeletedPair?.bcFromSupc,
          ig: isDeletedPair?.igFromSupc,
          ag: isDeletedPair?.agFromSupc
        };
        dispatch(actionCreators.saveNewPairMapping({ payload }));
      },
      onCancel() {
        // Handle cancellation
        console.log('Cancelled');
      }
    });
  };

  const showDifferentHierarchyConfirmation = () => {
    Modal.confirm({
      title: 'The selected SUPC pair belongs to different BC-IG-AG categories. Do you wish to proceed?"',
      content: (
        <div>
          <p>From Supc: {isValidPair.fromSupc}</p>
          <p>
            BC-IG-AG:{' '}
            {`${isDeletedPair?.bcFromSupc || ''}-${isDeletedPair?.igFromSupc || ''}-${isDeletedPair?.agFromSupc || ''}`}
          </p>
          <hr />
          <p>To Supc: {isValidPair.toSupc}</p>
          <p>
            BC-IG-AG:{' '}
            {`${isDeletedPair?.bcToSupc || ''}-${isDeletedPair?.igToSupc || ''}-${isDeletedPair?.agToSupc || ''}`}
          </p>
        </div>
      ),
      onOk() {
        // Handle confirmation
        console.log('Confirmed');
        const payload = {
          pairType: selectedPairType,
          fromSupc: newPairFromSupc,
          toSupc: newPairToSupc,
          bc: isDeletedPair?.bcFromSupc,
          ig: isDeletedPair?.igFromSupc,
          ag: isDeletedPair?.agFromSupc
        };
        dispatch(actionCreators.saveNewPairMapping({ payload }));
      },
      onCancel() {
        // Handle cancellation
        console.log('Cancelled');
      }
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    setCurrentPage(0);
    previousPageRef.current = 0;
    addPropertyOnFilters(dataIndex, selectedKeys[0]);
  };

  const handleNumericSearch = (selectedKeys, operator, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setComparisonOperator(operator ? operator : 'EQUAL');
    setSearchedColumn(dataIndex);
    setCurrentPage(0);
    previousPageRef.current = 0;
    const condition = { operatorType: operator, value: selectedKeys };
    addPropertyOnFilters(dataIndex, condition);
  };

  const handleReset = (clearFilters, column) => {
    form.resetFields();
    clearFilters();
    setSearchText(null);
    console.log(column);
    removeProperty(column);
  };

  const onNumericSearch = (values, selectedKeys, confirm, dataIndex) => {
    console.log(values, dataIndex, selectedKeys);
    const operator = values.operator ? values.operator : 'EQUAL';
    handleNumericSearch(values.numericInput, operator, confirm, dataIndex);
  };

  const addPropertyOnFilters = (key, value) => {
    // when new filters are appending filter object will be updated.
    dispatch(actionCreators.saveColumnFilters({ payload: { key, value } }));
  };

  // when filters are removing filter object will be updated.
  const removeProperty = key => {
    dispatch(actionCreators.resetColumnFilters({ payload: { key } }));
  };

  const handleOperatorChange = value => {
    setComparisonOperator(value);
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    )
  });

  const getNumericColumnSearchProps = dataIndex => ({
    filterDropdown: ({ selectedKeys, confirm, clearFilters }) => (
      <Form onFinish={e => onNumericSearch(e, selectedKeys, confirm, dataIndex)} form={form}>
        <div
          style={{
            padding: 8,
            display:
              dataIndex === 'updatedBy' || dataIndex === 'updatedDate' || dataIndex == 'attribute' ? 'none' : 'block'
          }}
          onKeyDown={e => e.stopPropagation()}
        >
          <Form.Item name="operator">
            <Select
              defaultValue="equals"
              onChange={handleOperatorChange}
              style={{ width: 120, marginBottom: 8, display: 'block' }}
            >
              <Select.Option value="EQUAL">Equals</Select.Option>
              <Select.Option value="GREATER_THAN">Greater Than</Select.Option>
              <Select.Option value="LESS_THAN">Less Than</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="numericInput">
            <Input
              style={{
                marginBottom: 8,
                display: 'block'
              }}
              type="number"
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={`${selectedKeys[0] || ''}`}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, dataIndex)}
              size="small"
              style={{
                width: 90
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      </Form>
    ),
    filterIcon: filtered => {
      console.log(dataIndex);
      if (dataIndex === 'updatedBy' || dataIndex === 'updatedDate' || dataIndex == 'attribute') {
        return null; // If the dataIndex is "updatedBy" or "updatedDate", hide the filter icon
      }
      return (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined
          }}
        />
      );
    }
  });

  const getFormattedBrands = () => {
    pairTypes.sort();
    const formatted = [];

    _.forEach(pairTypes, elem => {
      formatted.push({ text: elem.type, value: elem.type });
    });
    return formatted;
  };

  const dataCell = (data, width, textAlign, numeric) => {
    let formattedData = data;
    if (numeric) {
      formattedData = data.toLocaleString();
    }
    let padding = textAlign == 'left' ? { paddingLeft: '5px' } : textAlign == 'right' ? { paddingRight: '5px' } : {};
    let align = textAlign ? { textAlign } : {};
    return (
      <div className="dc" style={{ ...align, ...padding, width }} title={formattedData}>
        {formattedData}
      </div>
    );
  };

  const qualifierDetails = (data, width, textAlign, numeric) => {
    if (data && data !== null) {
      let formattedData = JSON.parse(data);
      return (
        <>
          <div style={{ width }}>
            {formattedData?.map(tag => {
              let color = 'geekblue';
              return (
                <Tooltip
                  title={
                    <>
                      <div>From value: {tag?.from_value.toUpperCase()}</div>
                      <div>To value: {tag?.to_value.toUpperCase()}</div>
                    </>
                  }
                  key={tag?.code}
                >
                  <Tag color={color} key={tag?.code}>
                    {tag?.code.toUpperCase()}
                  </Tag>
                </Tooltip>
              );
            })}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ width }}></div>
        </>
      );
    }
  };

  const columns = [
    {
      title: 'From SUPC',
      dataIndex: 'fromSupc',
      key: 'fromSupc',
      render: data => dataCell(data, 80),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 1
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromSupc'),
      fixed: 'left',
      width: 100
    },
    {
      title: 'From Item Description',
      dataIndex: 'fromItemDescription',
      key: 'fromItemDescription',
      render: data => dataCell(data, 120, 'left'),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 33
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('fromItemDescription'),
      fixed: 'left'
    },
    {
      title: 'To SUPC',
      dataIndex: 'toSupc',
      key: 'toSupc',
      render: data => dataCell(data, 80),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toSupc'),
      fixed: 'left'
    },
    {
      title: 'To Item Description',
      dataIndex: 'toItemDescription',
      key: 'toItemDescription',
      render: data => dataCell(data, 120, 'left'),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 34
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toItemDescription'),
      fixed: 'left'
    },
    {
      title: 'SA Lot Number',
      dataIndex: 'saLotNumber',
      key: 'saLotNumber',
      render: data => dataCell(data, 120),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 16
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('saLotNumber')
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 15
      },
      sortDirections: ['ascend', 'descend'],
      filters: getFormattedBrands(),
      editable: true,
      render: (text, record) => (
        <Select
          value={text}
          style={{ width: 120 }}
          onChange={value => handleCellChange(value, record, 'type')}
          options={getFormattedBrands().map(brand => ({
            label: brand.value,
            value: brand.value,
            disabled: brand.value === 'I' || !permissions.includes('BRANDCONVERSIONAPP.EDIT')
          }))}
        />
      )
    },
    {
      title: 'Qualifier Details',
      dataIndex: 'qualifierDetails',
      key: 'qualifierDetails',
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 15
      },
      sortDirections: ['ascend', 'descend'],
      filters: [
        {
          text: 'UPG1',
          value: 'UPG1'
        }
      ],
      render: data => qualifierDetails(data, 120)
    },
    {
      title: 'From Sysco Brand Indicator',
      dataIndex: 'fromSyscoBrandIndicator',
      key: 'fromSyscoBrandIndicator',
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 5
      },
      render: data => dataCell(data, 120),
      sortDirections: ['descend', 'ascend'],
      filters: [
        {
          text: 'Y',
          value: 'Y'
        },
        {
          text: 'N',
          value: 'N'
        }
      ]
    },
    {
      title: 'To Sysco Brand Indicator',
      dataIndex: 'toSyscoBrandIndicator',
      key: 'toSyscoBrandIndicator',
      render: data => dataCell(data, 120),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 6
      },
      sortDirections: ['ascend', 'descend'],
      filters: [
        {
          text: 'Y',
          value: 'Y'
        },
        {
          text: 'N',
          value: 'N'
        }
      ]
    },
    {
      title: 'From Brand Code',
      dataIndex: 'fromBrandCode',
      key: 'fromBrandCode',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 7
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromBrandCode')
    },
    {
      title: 'To Brand Code',
      dataIndex: 'toBrandCode',
      key: 'toBrandCode',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 8
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toBrandCode')
    },
    {
      title: 'From Brand Description',
      dataIndex: 'fromBrandDescription',
      key: 'fromBrandDescription',
      render: data => dataCell(data, 100, 'left'),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 2
      },
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('fromBrandDescription')
    },
    {
      title: 'To Brand Description',
      dataIndex: 'toBrandDescription',
      key: 'toBrandDescription',
      render: data => dataCell(data, 100, 'left'),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 4
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toBrandDescription')
    },
    {
      title: 'From Total Case Eq Sold',
      dataIndex: 'fromTotalCaseEqSold',
      key: 'fromTotalCaseEqSold',
      render: data => dataCell(data, 140, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 9
      },
      sortDirections: ['descend', 'ascend'],
      ...getNumericColumnSearchProps('fromTotalCaseEqSold')
    },
    {
      title: 'To Total Case Eq Sold',
      dataIndex: 'toTotalCaseEqSold',
      key: 'toTotalCaseEqSold',
      render: data => dataCell(data, 120, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 10
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toTotalCaseEqSold')
    },
    {
      title: 'From Total Net Sales',
      dataIndex: 'fromTotalNetSales',
      key: 'fromTotalNetSales',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 11
      },
      sortDirections: ['descend', 'ascend'],
      ...getNumericColumnSearchProps('fromTotalNetSales')
    },
    {
      title: 'To Total Net Sales',
      dataIndex: 'toTotalNetSales',
      key: 'toTotalNetSales',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 12
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toTotalNetSales')
    },
    {
      title: 'From Total GP',
      dataIndex: 'fromTotalGp',
      key: 'fromTotalGp',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 13
      },
      sortDirections: ['descend', 'ascend'],
      ...getNumericColumnSearchProps('fromTotalGp')
    },
    {
      title: 'To Total GP',
      dataIndex: 'toTotalGp',
      key: 'toTotalGp',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 14
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toTotalGp')
    },
    {
      title: 'From Usage Lot Name',
      dataIndex: 'fromUsageLotName',
      key: 'fromUsageLotName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 19
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('fromUsageLotName'),
      hidden: true
    },
    {
      title: 'To Usage Lot Name',
      dataIndex: 'toUsageLotName',
      key: 'toUsageLotName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 20
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toUsageLotName')
    },
    {
      title: 'From Usage Lot UUOM Name',
      dataIndex: 'fromUsageLotUuomName',
      key: 'fromUsageLotUuomName',
      render: data => dataCell(data, 140),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 21
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('fromUsageLotUuomName')
    },
    {
      title: 'To Usage Lot UUOM Name',
      dataIndex: 'toUsageLotUuomName',
      key: 'toUsageLotUuomName',
      render: data => dataCell(data, 140),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 22
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toUsageLotUuomName')
    },
    {
      title: 'From Univ UOM Name',
      dataIndex: 'fromUnivUomName',
      key: 'fromUnivUomName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 23
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('fromUnivUomName')
    },
    {
      title: 'To Univ UOM Name',
      dataIndex: 'toUnivUomName',
      key: 'toUnivUomName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 24
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toUnivUomName')
    },
    {
      title: 'From Univ UOM Qty',
      dataIndex: 'fromUnivUomQty',
      key: 'fromUnivUomQty',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 25
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('fromUnivUomQty')
    },
    {
      title: 'To Univ UOM Qty',
      dataIndex: 'toUnivUomQty',
      key: 'toUnivUomQty',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 26
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toUnivUomQty')
    },
    {
      title: 'From UOM Name',
      dataIndex: 'fromUomName',
      key: 'fromUomName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 27
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('fromUomName')
    },
    {
      title: 'To UOM Name',
      dataIndex: 'toUomName',
      key: 'toUomName',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 28
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('toUomName')
    },
    {
      title: 'From UOM Qty',
      dataIndex: 'fromUomQty',
      key: 'fromUomQty',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 29
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('fromUomQty')
    },
    {
      title: 'To UOM Qty',
      dataIndex: 'toUomQty',
      key: 'toUomQty',
      render: data => dataCell(data, 100, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 30
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toUomQty')
    },
    {
      title: 'From Equivalent UOM Qty',
      dataIndex: 'fromEquivalentUomQty',
      key: 'fromEquivalentUomQty',
      render: data => dataCell(data, 130, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 31
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('fromEquivalentUomQty')
    },
    {
      title: 'To Equivalent UOM Qty',
      dataIndex: 'toEquivalentUomQty',
      key: 'toEquivalentUomQty',
      render: data => dataCell(data, 130, 'right', true),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 32
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('toEquivalentUomQty')
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: data => dataCell(data, 100),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 33
      },
      sortDirections: ['ascend', 'descend'],
      ...getNumericColumnSearchProps('updatedBy')
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedDate',
      key: 'updatedDate',
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 34
      },
      sortDirections: ['ascend', 'descend'],
      render: updatedDate => dataCell(updatedDate ? moment(updatedDate).format('MM/DD/YYYY h:mm:ss A') : '', 130),
      ...getNumericColumnSearchProps('updatedDate')
    },
    {
      title: 'Attribute String',
      dataIndex: 'attributeString',
      key: 'attributeString',
      render: data => dataCell(data, 400, 'left'),
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 18
      },
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('attributeString'),
      hidden: true
    }
  ];

  /**
   * Store all updatesd pair type records.
   * @param {*} newPairType
   * @param {*} record
   * @param {*} dataIndex
   */
  const handleCellChange = (newPairType, record, dataIndex) => {
    const payload = {
      pairType: newPairType,
      fromSupc: record.fromSupc,
      toSupc: record.toSupc,
      bc: record.bcName,
      ig: record.igName,
      ag: record.agName,
      lotNumber: record.saLotNumber
    };
    setUpdatedPairTypes(prevState => {
      // Filter out the existing object with the same fromSupc and toSupc
      const filteredState = prevState.filter(
        item => !(item.fromSupc === record.fromSupc && item.toSupc === record.toSupc)
      );
      // Add the new payload
      return [...filteredState, payload];
    });

    //update with new value
    record.type = newPairType;
  };

  // based on the selection of the toggle buttons column headers will be updated.
  const arrangeColumns = () => {
    let newcolumns = columns;
    if (!filterParams?.showOtherAttributes) {
      console.log(selectedColumns.length);
      // Remove the column from the columns array
      newcolumns = columns.filter(column => !otherAttributeColumns.includes(column.key));
    }
    if (filterParams?.showOsdAttributes) {
      // Extract unique keys from the 'attribute' array
      const uniqueKeys = [
        ...new Set(
          itemDetails?.allItemList?.data.flatMap(item =>
            item.attribute.map(attr => JSON.stringify({ key: attr.key, title: attr.title }))
          )
        )
      ].map(str => JSON.parse(str));

      // Create columns based on unique keys
      const osdAttrColumns = uniqueKeys
        .filter(attr => validAttributes?.validAttributes?.data.includes(attr.title))
        .map(attr => {
          // Convert key to title case for the title
          const index = attr.key
            .split(' ')
            .map((word, index) =>
              index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join('');

          const titleDisplay = attr.title
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

          return {
            title: titleDisplay,
            dataIndex: attr.key,
            render: data => dataCell(data, 100),
            key: attr.key, // Using camelCase as per your requirement
            sortDirections: ['descend', 'ascend'],
            ...getNumericColumnSearchProps('attribute')
            //hadle it later
          };
        });

      newcolumns = newcolumns.concat(osdAttrColumns);
    }
    if (!filterParams?.showOsdAttributeString) {
      // Remove attribute-string column from the columns array
      newcolumns = newcolumns.filter(column => column.key.toLowerCase() !== 'attributestring');
    }
    setSelectedColumns(newcolumns);
    console.log(selectedColumns);
  };

  const arrangeOsdAttributeData = () => {
    let updatedTableData = itemDetails?.allItemList?.data.forEach(obj => {
      let expandedAttributes = {};

      obj.attribute.forEach(attr => {
        const camelCaseName = attr.key
          .replace(/\s+/g, ' ')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
          .replace(/\s+/g, '');
        expandedAttributes[camelCaseName] = attr.value;
      });
      Object.assign(obj, expandedAttributes);
    });

    console.log(itemDetails.allItemList.data);
    setTableData(updatedTableData);
  };

  const onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
    setCurrentPage(current > 0 ? current - 1 : 0);
    setPageSize(pageSize);
    previousPageRef.current = current > 0 ? current - 1 : 0;
    setTriggerBySearch(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const saveItemChnges = () => {
    deletedPairs.forEach(deletedPair => {
      // Find index of matching pair in updatedPairTypes
      const index = updatedPairTypes.findIndex(
        updatedPair => updatedPair.fromSupc === deletedPair.fromSupc && updatedPair.toSupc === deletedPair.toSupc
      );
      // If a matching pair is found, remove it from updatedPairTypes
      if (index !== -1) {
        updatedPairTypes.splice(index, 1);
      }
    });
    console.log(deletedPairs);
    console.log(updatedPairTypes);
    const payload = {
      deleted: deletedPairs,
      updated: updatedPairTypes
    };
    dispatch(actionCreators.saveItemChanges({ payload }));
  };

  const submitPair = () => {
    const payload = {
      pairType: selectedPairType,
      fromSupc: newPairFromSupc,
      toSupc: newPairToSupc,
      bc: filterParams?.bc,
      ig: filterParams?.ig,
      ag: filterParams?.ag
    };
    //call for deleted pair validation.
    setIsSubmittedNewPair(true);
    dispatch(actionCreators.validateNewPair({ payload }));
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedPairType('');
    setNewPairToSupc('');
    setNewPairFromSupc('');
  };

  const handleFromSupcSearchClick = () => {
    setFromSupcModalVisible(true);
    setNewPairFromSupc('');
    setSelectedPairType('');
    setFilteredSupcList([]);
    setNewPairTotal(0);
    setNewPairPageNo(0);
    setFromInputValue('');
    //load supc data to the modal.
    const type = 'from';
  };

  const handleToSupcSearchClick = () => {
    setToSupcModalVisible(true);
    setNewPairToSupc('');
    setSelectedPairType('');
    setFilteredSupcList([]);
    setNewPairTotal(0);
    setNewPairPageNo(0);
    setToInputValue('');
    //if user clicks to supc search 1st this should trigger.
    const type = 'to';
  };

  const handleModalCancel = () => {
    setFromSupcModalVisible(false);
    setToSupcModalVisible(false);
    setFromInputValue('');
    setToInputValue('');
  };

  const handleModalClear = () => {
    setFromInputValue('');
    setToInputValue('');
  };

  const handleModalOk = () => {
    // Logic to handle OK button click in the modals
    setFromSupcModalVisible(false);
    setToSupcModalVisible(false);
  };

  const handlePairTypeChange = value => {
    setSelectedPairType(value);
  };

  const handleNewFromSupcChange = e => {
    console.log('fromsupc' + e.target.value);
    setNewPairFromSupc(e.target.value);
  };

  const handleNewToSupcChange = e => {
    setNewPairToSupc(e.target.value);
    console.log('tosupc');
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: <div style={{ fontSize: '10px' }}>Delete</div>,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      const allDeletedPairs = selectedRows.map(record => ({
        pairType: record.type,
        fromSupc: record.fromSupc,
        toSupc: record.toSupc,
        bc: record.bcName,
        ig: record.igName,
        ag: record.agName,
        lotNumber: record.saLotNumber
      }));

      // Concatenate the updatedPairs array with the existing deletedPairs array
      setDeletedPairs(allDeletedPairs.map(record => ({ ...record })));
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const handleFromSupcTypeChange = value => {
    setFromSupcSelectedType(value);
  };

  const handleToSupcTypeChange = value => {
    setToSupcSelectedType(value);
  };

  // Function to filter brands based on the condition
  const filterSupc = (type, pageNo) => {
    // For search button click load the data.
    if (type == 'from') {
      dispatch(
        actionCreators.fetchDistinctSupcList({
          searchPattern: fromSupcSelectedType,
          searchValue: fromInputValue,
          brandType: 'from',
          sortParams: null,
          pageNo: pageNo ? pageNo - 1 : newPairPageNo,
          pageSize: 10
        })
      );
    } else {
      dispatch(
        actionCreators.fetchDistinctSupcList({
          searchPattern: toSupcSelectedType,
          searchValue: toInputValue,
          brandType: 'to',
          sortParams: null,
          pageNo: pageNo ? pageNo - 1 : newPairPageNo,
          pageSize: 10
        })
      );
    }
  };

  const handleNewFromSupcSearch = e => {
    setFromInputValue(e.target.value);
    // filterSupc(fromSupcSelectedType, e.target.value.toLowerCase());
  };

  const handleNewToSupcSearch = e => {
    setToInputValue(e.target.value);
    // filterSupc(toSupcSelectedType, e.target.value);
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      // Load next set of items when scrolled to the bottom
      setVisibleItems(prevVisibleItems => prevVisibleItems + 10);
    }
  };

  const handlePageChange = (page, type) => {
    setNewPairPageNo(page);
    filterSupc(type, page);
  };

  const showDoNotConvertModal = () => {
    setIsDoNotConvertModalVisible(true);
  };
  /**
   * Catch all events of table on change.
   * @param {*} pagination
   * @param {*} filters
   * @param {*} sorter
   * @param {*} extra
   */
  const onChange = (pagination, filters, sorter, extra) => {
    setTriggerBySearch(false);
    console.log('params', pagination, filters, sorter, extra);
    if (extra.action === 'filter') {
      Object.entries(filters).forEach(([key, value]) => {
        if (
          key === 'fromSyscoBrandIndicator' ||
          key === 'toSyscoBrandIndicator' ||
          key === 'type' ||
          key === 'qualifierDetails'
        ) {
          addPropertyOnFilters(key, value);
        }
      });
    } else if (extra.action === 'sort') {
      if (!Array.isArray(sorter)) {
        const key = sorter.columnKey;
        const value = sorter.order;
        const keysToRemove = [];
        if (Array.isArray(columnSorters)) {
          columnSorters.forEach(sorter => {
            const sorterKey = sorter.key;
            if (sorterKey !== key) {
              keysToRemove.push(sorterKey);
            }
          });
        }
        //remove those keys.
        keysToRemove.forEach(key => {
          dispatch(actionCreators.resetColumnSorts({ payload: { key } }));
        });

        if (sorter.order) {
          dispatch(actionCreators.saveColumnSorts({ payload: { key, value } }));
        } else {
          dispatch(actionCreators.resetColumnSorts({ payload: { key } }));
        }
      } else {
        //remove keys
        const columnSorterKeys = columnSorters.map(sorter => sorter.key);
        const sorterKeys = sorter.map(s => s.columnKey);
        // Find keys that exist in columnSorters but not in sorter
        const keysToRemove = columnSorterKeys.filter(key => !sorterKeys.includes(key));
        keysToRemove.forEach(key => {
          dispatch(actionCreators.resetColumnSorts({ payload: { key } }));
        });
        // add new sort keys
        sorter.forEach(s => {
          const key = s.columnKey;
          const value = s.order;
          if (s.order) {
            dispatch(actionCreators.saveColumnSorts({ payload: { key, value } }));
          } else {
            dispatch(actionCreators.resetColumnSorts({ payload: { key } }));
          }
        });
      }
    }
  };

  const generateReport = () => {
    // Construct the URL
    const url = `${reportURL}/generate-excel`;
    // Define the request body
    const requestBody = {
      bc: filterParams?.bc,
      ig: filterParams?.ig,
      ag: filterParams?.ag
    };
    // Make the POST request
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        notification.success({
          message: 'Report Generation',
          description: 'The report generation is in progress. Please try to download in a few minutes.'
        });
        // Optionally, return response.json() if you expect data from the server
      })
      .catch(error => {
        console.error('There was a problem with the generate report operation:', error);
        notification.error({
          message: 'Error',
          description: 'There was a problem when generating the report. Please try again later.'
        });
      });
  };

  const downloadReport = () => {
    // Construct the URL
    const url = `${reportURL}/download-excel?bc=${filterParams?.bc}&ig=${filterParams?.ig}&ag=${filterParams?.ag}`;
    // Make the GET request
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse response JSON
      })
      .then(data => {
        console.log(data); // Log the response data
        if (data.status === 30) {
          window.open(data.downloadUrl, '_blank');
          notification.success({
            message: 'Report Downloaded',
            description: 'The report has been successfully downloaded.'
          });
        } else if (data.status > 30) {
          notification.error({
            message: 'Error',
            description:
              'There is no report available to download. Please generate a report request for this hierarchy.'
          });
        } else if (data.status === 0 || data.status === 10) {
          notification.error({
            message: 'Info',
            description: 'Report generation is in progress.'
          });
        } else {
          notification.error({
            message: 'Info',
            description:
              'Please click on the "Generate Report" button.Thre are no available report requests for this hierarchy'
          });
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        notification.error({
          message: 'Error',
          description: 'There was a problem when downloading the report. Please try again later.'
        });
      });
  };

  return (
    <>
      <DoNotConvertBrandModal
        isVisible={isDoNotConvertModalVisible}
        handleCancel={() => setIsDoNotConvertModalVisible(false)}
      />
      <Spin spinning={isSavingItemChanges}>
        <Table
          showHeader={tableData && tableData.length > 0 ? true : false}
          key={resetKey}
          scroll={{ x: true, y: window.innerHeight - 415 }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
          columns={selectedColumns.map((column, index) => ({
            ...column,
            className: column.title.startsWith('From')
              ? 'even-column'
              : column.title.startsWith('To')
              ? 'odd-column'
              : 'type-column'
          }))}
          dataSource={tableData}
          pagination={false}
          rowClassName={'uniform-rows'}
          onChange={onChange}
          size="small"
        />
      </Spin>
      <div className="footer-base">
        <div className="footer-left">
          <div className="footer-left-buttons">
            <Button
              type="primary"
              onClick={saveItemChnges}
              disabled={
                !(deletedPairs.length > 0 || updatedPairTypes.length > 0) ||
                isSavingItemChanges ||
                !permissions.includes('BRANDCONVERSIONAPP.EDIT')
              }
            >
              SUBMIT CHANGES
            </Button>
            <Button type="primary" onClick={showModal} disabled={!permissions.includes('BRANDCONVERSIONAPP.EDIT')}>
              ADD A PAIR
            </Button>
            <Button
              type="primary"
              onClick={showDoNotConvertModal}
              disabled={!permissions.includes('BRANDCONVERSIONAPP.EDIT')}
            >
              "Do Not Convert" Brands
            </Button>
          </div>
        </div>
        <div className="footer-right">
          {tableData && tableData.length > 0 && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Pagination
                showSizeChanger
                onChange={onShowSizeChange}
                current={currentPage + 1}
                total={totalRecordCount}
              />
              <div>
                {filterParams?.bc && filterParams?.ig && filterParams?.ag && (
                  <>
                    <Button type="primary" onClick={generateReport} style={{ marginRight: '10px' }}>
                      Generate Report
                    </Button>
                    <Button type="primary" onClick={downloadReport}>
                      Download Report
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* modal pop up for add new pair*/}
      <Modal
        title="NEW PAIR ADDITION"
        visible={visible}
        onOk={submitPair}
        onCancel={handleCancel}
        footer={[
          <Button
            key="apply"
            type="primary"
            onClick={submitPair}
            disabled={
              !selectedPairType ||
              !newPairToSupc ||
              !newPairFromSupc ||
              selectedPairType.trim() === '' ||
              newPairToSupc.trim() === '' ||
              newPairFromSupc.trim() === '' ||
              (isSavingPair && !isFaild)
            }
          >
            Submit Pair
          </Button>
        ]}
      >
        <Spin spinning={isSavingPair && !isFaild}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ marginRight: '10px' }}>
                <label>Enter From SUPC</label>
                <Input
                  placeholder="From SUPC..."
                  value={newPairFromSupc}
                  onChange={handleNewFromSupcChange}
                  suffix={<SearchOutlined onClick={handleFromSupcSearchClick} style={{ cursor: 'pointer' }} />}
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <label>Enter To SUPC</label>
                <Input
                  placeholder="To SUPC..."
                  value={newPairToSupc}
                  onChange={handleNewToSupcChange}
                  suffix={<SearchOutlined onClick={handleToSupcSearchClick} style={{ cursor: 'pointer' }} />}
                />
              </div>
              <div style={{ marginRight: '10px' }}>
                <label>Select Pair Type</label>
                <Select
                  placeholder="Pair Type"
                  style={{ width: 200 }}
                  onChange={handlePairTypeChange}
                  value={selectedPairType}
                  options={[
                    // Map the pairTypes to label and value
                    ...pairTypes.map(pair => ({ label: pair.type, value: pair.type, disabled: pair.type === 'I' }))
                  ]}
                ></Select>
              </div>
            </div>
            <Modal
              title="From SUPC Search"
              visible={isFromSupcModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
              footer={[
                <Button key="clear" onClick={handleModalClear}>
                  Clear
                </Button>,
                <Button key="apply" type="primary" onClick={handleModalOk}>
                  Apply
                </Button>
              ]}
            >
              {/* Content of the search modal for From SUPC */}
              <Spin spinning={isLoadingSupc}>
                <div style={{ color: '#6a737b' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <Select
                      defaultValue="contains"
                      style={{ width: 120, marginBottom: 16 }}
                      onChange={handleFromSupcTypeChange}
                    >
                      <Select.Option value="contains">Contains</Select.Option>
                      <Select.Option value="starts_with">Starts with</Select.Option>
                      <Select.Option value="ends_with">Ends with</Select.Option>
                    </Select>
                  </div>
                  <div style={{ marginBottom: '20px', display: 'inline-flex', alignItems: 'center' }}>
                    <Input placeholder="Enter text here" onChange={handleNewFromSupcSearch} value={fromInputValue} />
                    <Button
                      type="primary"
                      onClick={() => filterSupc('from')}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ marginLeft: '8px' }}
                    >
                      SEARCH
                    </Button>
                  </div>

                  <div>
                    <div style={{ overflow: 'auto', maxHeight: '300px' }} onScroll={handleScroll}>
                      <>
                        <Radio.Group onChange={e => handleNewFromSupcChange(e)} value={newPairFromSupc}>
                          {filteredSupcList.map(item => (
                            <Radio key={item.supc} value={item.supc}>
                              <span style={{ color: '#6a737b' }}>{item.itemDescription + '(' + item.supc + ')'}</span>
                            </Radio>
                          ))}
                        </Radio.Group>

                        <Pagination
                          current={newPairPageNo}
                          pageSize="10"
                          total={newPairTotal}
                          onChange={page => handlePageChange(page, 'from')}
                          style={{ marginTop: '16px' }}
                          showSizeChanger={false}
                        />
                      </>
                    </div>
                    <p style={{ marginTop: '20px' }}>Selected From Supc: {newPairFromSupc}</p>
                  </div>
                </div>
              </Spin>
            </Modal>
            <Modal
              title="To SUPC Search"
              visible={isToSupcModalVisible}
              onOk={handleModalOk}
              onCancel={handleModalCancel}
              footer={[
                <Button key="clear" onClick={handleModalClear}>
                  Clear
                </Button>,
                <Button key="apply" type="primary" onClick={handleModalOk}>
                  Apply
                </Button>
              ]}
            >
              {/* Content of the search modal for To SUPC */}
              <Spin spinning={isLoadingSupc}>
                <div style={{ color: '#6a737b' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <Select defaultValue="Contains" style={{ width: 120 }} onChange={handleToSupcTypeChange}>
                      <Select.Option value="contains">Contains</Select.Option>
                      <Select.Option value="starts_with">Starts with</Select.Option>
                      <Select.Option value="ends_with">Ends with</Select.Option>
                    </Select>
                  </div>
                  <div style={{ marginBottom: '20px', display: 'inline-flex', alignItems: 'center' }}>
                    <Input placeholder="Enter text here" onChange={handleNewToSupcSearch} value={toInputValue} />
                    <Button
                      type="primary"
                      onClick={() => filterSupc('to')}
                      icon={<SearchOutlined />}
                      size="small"
                      style={{ marginLeft: '8px' }}
                    >
                      SEARCH
                    </Button>
                  </div>

                  <div>
                    <div style={{ overflow: 'auto', maxHeight: '300px' }} onScroll={handleScroll}>
                      <Radio.Group
                        onChange={e => handleNewToSupcChange(e)}
                        value={newPairToSupc} // Assuming selectedSupc is the state variable to manage selected radio button
                      >
                        {filteredSupcList.slice(0, visibleItems).map(item => (
                          <Radio key={item.supc} value={item.supc}>
                            <span style={{ color: '#6a737b' }}>{item.itemDescription + '(' + item.supc + ')'}</span>
                          </Radio>
                        ))}
                      </Radio.Group>
                      <Pagination
                        current={newPairPageNo}
                        pageSize="10"
                        total={newPairTotal}
                        onChange={page => handlePageChange(page, 'to')}
                        style={{ marginTop: '16px' }}
                        showSizeChanger={false}
                      />
                    </div>
                    <p style={{ marginTop: '20px' }}>Selected To Supc: {newPairToSupc}</p>
                  </div>
                </div>
              </Spin>
            </Modal>
          </div>
        </Spin>
      </Modal>
    </>
  );
}
